import gql from "graphql-tag";

// #region Hackahton
export const GET_HACKATHON_HACKATHON_AND_IDEAS_UNAUTH = gql`
    query ($hackathon_id: ID!, $hackathon_uid: String) {
        hackathonHackathon(id: $hackathon_id, uid: $hackathon_uid) {
            id
            uid
            name
            description
            detailDescription
            startDatetime
            endDatetime
            maxTeamSize
            organization {
                id
                name
            }
            status
            statusDisplay
            phase
            phaseDisplay
            hasJudges
            hasPrizes
            hasMentors
            hasPitches
            hasVolunteers
            hasGoals
            ideas {
                edges {
                    node {
                        id
                        uid
                        name
                        description
                    }
                }
            }
        }
    }
`;
// #endregion

// #region Hackahton Ideas
export const GET_HACKATHON_IDEA_UN_AUTH = gql`
    query ($idea_id: ID!, $idea_uid: String) {
        hackathonIdeaIdea(idea_id: $idea_id, uid: $idea_uid) {
            id
            name
            description
            created
        }
    }
`;

// #endregion

// #region Hackathon Prizes
export const GET_HACKATHON_PRIZES_UN_AUTH = gql`
    query ($hackathon_id: ID!, $hackathon_uid: String) {
        allHackathonPrizes(
            hackathon: $hackathon_id
            hackathonUid: $hackathon_uid
        ) {
            edges {
                node {
                    id
                    description
                    position
                }
            }
        }
    }
`;
// #endregion

// #region Hackathon Goals
export const GET_HACKATHON_GOALS_UN_AUTH = gql`
    query ($hackathon_id: ID!, $hackathon_uid: String) {
        allHackathonGoal(
            hackathon: $hackathon_id
            hackathonUid: $hackathon_uid
        ) {
            edges {
                node {
                    id
                    name
                    priority
                    description
                }
            }
        }
    }
`;
// #endregion
